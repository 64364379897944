<template>
  <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
    <loading :active.sync="isLoading" :is-full-page="false" />

    <form @submit.prevent="handleSubmit(submitStep)" class="px-6">
      <div class="flex items-center mb-3 font-bold text-gray-500 mt-5">
        <span>{{
          $t(
            'components.rentPricingPlanManagement.addEdit.steps.extras.title.addonsPlan'
          )
        }}</span>
      </div>
      <section>
        <div class="flex items-center my-5 text-sm font-bold text-gray-500 ">
          <span>{{
            $t(
              'components.rentPricingPlanManagement.addEdit.steps.extras.title.addons'
            )
          }}</span>
          <div
            class="col-span-1 ml-2 cursor-pointer focus:text-gray-400"
            @click="onAddRentalAddons"
          >
            <i class="fas fa-plus-circle" style="color: black"></i>
          </div>
        </div>
        <div
          v-for="(rentAddon, rentAddonIndex) in rentAddons"
          :key="rentAddonIndex"
        >
          <div class="flex items-center">
            <div class="flex items-start  gap-x-1 w-11/12  ">
              <div class="w-6/12   ">
                <AppInput
                  v-model="rentAddon.type"
                  type="richselect"
                  :name="
                    $t(
                      'components.rentPricingPlanManagement.addEdit.steps.extras.title.addonsType'
                    )
                  "
                  :label="
                    $t(
                      'components.rentPricingPlanManagement.addEdit.steps.extras.title.addonsType'
                    )
                  "
                  :placeholder="
                    $t(
                      'components.rentPricingPlanManagement.addEdit.steps.extras.placeHolder.addonsType'
                    )
                  "
                  value-attribute="value"
                  text-attribute="text"
                  :options="rentAddonsChoices"
                  hide-search-box
                />
              </div>

              <div class="w-6/12">
                <AppInput
                  v-model.number="rentAddon.per_unit_fee"
                  type="text"
                  rules="required"
                  :name="
                    $t(
                      'components.rentPricingPlanManagement.addEdit.steps.extras.title.addonsCost'
                    )
                  "
                  :label="
                    $t(
                      'components.rentPricingPlanManagement.addEdit.steps.extras.title.addonsCost'
                    )
                  "
                  placeholder="e.g. 5"
                  :haveUnitText="true"
                  :unitText="selectedFleetCurrency"
                />
              </div>
            </div>

            <div class="flex  w-1/12 item-center justify-center">
              <div
                class="col-span-1 cursor-pointer focus:text-gray-400"
                @click="onRemoveRentalAddons(rentAddonIndex)"
              >
                <i class="fas fa-minus-circle" style="color: #d90a20"></i>
              </div>
            </div>
          </div>
        </div>
      </section>

      <button type="submit" ref="submitButton" class="hidden">
        Save
      </button>
    </form>
  </ValidationObserver>
</template>

<script>
import { useEndpoints } from '@/composables'
export default {
  name: 'Step2',

  components: {},

  props: {
    primaryKey: {
      required: true,
    },
    formData: {
      required: false,
    },
    currency: {
      default: '',
    },
    isEditing: {
      type: Boolean,
      required: false,
      default: false,
    },
    isExtrasDataLoaded: {
      type: Boolean,
      required: false,
      default: false,
    },
    fleets: {
      type: Array,
      default: () => [],
    },
    rentAddonsChoices: {
      type: Array,
      default: () => [],
    },

    fleetKeyProxy: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      isLoading: false,
      rentAddons: [],
      deleteRentAddons: [],
    }
  },

  computed: {
    selectedFleet() {
      return this.fleetKeyProxy
        ? this.fleets.find((fleet) => fleet.id == this.fleetKeyProxy)
        : null
    },
    selectedFleetCurrency() {
      return this.selectedFleet
        ? this.selectedFleet?.country?.currency_symbol
        : null
    },
    selectedFleetCurrencyFormatted() {
      return this.selectedFleetCurrency ? `(${this.selectedFleetCurrency})` : ''
    },
  },

  watch: {
    formData: {
      immediate: true,
      deep: false,
      handler(data) {
        if (data) {
          this.rentAddons = data.rentAddons
        }
      },
    },
    isExtrasDataLoaded: {
      immediate: true,
      deep: false,
      handler(status) {
        this.isLoading = status
      },
    },
  },

  methods: {
    getVatIncludedAmount(amount) {
      if (amount && this.selectedFleetCurrency) {
        const vatAmount = (Number(this.taxPercentage) * Number(amount)) / 100
        const totalAmount = Number(amount) + vatAmount
        return `${this.selectedFleetCurrency}${Number(
          totalAmount.toFixed(2)
        )} Inc. VAT`
      } else {
        return ''
      }
    },
    onAddRentalAddons() {
      if (this.rentAddons.length < this.rentAddonsChoices.length) {
        this.rentAddons.unshift({
          type: null,
          per_unit_fee: 0,
        })
      }
    },
    onRemoveRentalAddons(index) {
      if (this.isEditing) {
        this.deleteRentAddons.push(this.rentAddons[index])
      }
      this.rentAddons.splice(index, 1)
    },
    async submit() {
      await this.$refs.submitButton.click()
    },

    async submitStep() {
      const requests = []

      //add or update rent addons
      const rentAddonsDataReq = []
      this.rentAddons.forEach((element) => {
        const isRentAddonUpdateReq = 'id' in element
        const reqRentAddonMethod = isRentAddonUpdateReq ? 'PATCH' : 'POST'
        const reqRentAddonUrl = isRentAddonUpdateReq
          ? useEndpoints.rentPricingPlan.updateRentAddons(element.id)
          : useEndpoints.rentPricingPlan.createRentAddons()
        const rentAddonReqData = new FormData()

        if (isRentAddonUpdateReq) {
          rentAddonReqData.append('type', element.type)
          rentAddonReqData.append('per_unit_fee', element.per_unit_fee)
        } else {
          rentAddonReqData.append('type', element.type)
          rentAddonReqData.append('per_unit_fee', element.per_unit_fee)
          rentAddonReqData.append('rental_pricing_plan', this.primaryKey)
        }

        const rentAddonReq = this.$http({
          url: reqRentAddonUrl,
          method: reqRentAddonMethod,
          data: rentAddonReqData,
        })
        rentAddonsDataReq.push(rentAddonReq)
      })

      requests.push(...rentAddonsDataReq)

      //delete rent addons

      let deleteRentAddonReqs = []
      if (this.isEditing && this.deleteRentAddons.length) {
        this.deleteRentAddons.forEach((element) => {
          if ('id' in element) {
            const deleteRentAddonReq = this.$http({
              url: useEndpoints.rentPricingPlan.deleteRentAddons(element.id),
              method: 'DELETE',
            })
            deleteRentAddonReqs.push(deleteRentAddonReq)
          }
        })
      }
      if (deleteRentAddonReqs.length) {
        requests.push(...deleteRentAddonReqs)
      }

      this.isLoading = true
      await this.$http
        .all(requests)
        .then(
          this.$http.spread((...responses) => {
            const [...deleteRentPackageReqs] = responses

            //reset deleteArray
            this.deleteRentPackages = []
            console.log('res-step2', deleteRentPackageReqs.length)

            this.deleteRentAddons = []

            const message = `Pricing plan extra settings ${
              this.isEditing ? 'updated' : 'added'
            } successfully`

            this.$notify({
              group: 'bottomLeft',
              type: 'success',
              title: 'Success',
              text: message,
            })
            this.$emit('save', { step: 2 })
          })
        )
        .catch((err) => {
          // todo: emit fail?
          console.error('Error occurred!', err)
          this.$notify({
            group: 'bottomLeft',
            type: 'error',
            title: 'Error!',
            text: err.response.data.message,
          })
        })
        .finally(() => (this.isLoading = false))
    },
  },
}
</script>

<style lang="scss" scoped></style>
